type IconProps = {
  width?: number;
  height?: number;
  type?: string;
  className?: string;
};

function Icon({
  width = 16,
  height = 16,
  type = "globe",
  className = "",
}: IconProps) {
  if (type === "globe") {
    return (
      <svg
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <circle
          cx="12"
          cy="12"
          r="7.25"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
          d="M15.25 12C15.25 16.5 13.2426 19.25 12 19.25C10.7574 19.25 8.75 16.5 8.75 12C8.75 7.5 10.7574 4.75 12 4.75C13.2426 4.75 15.25 7.5 15.25 12Z"
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
          d="M5 12H12H19"
        />
      </svg>
    );
  } else if (type === "user") {
    return (
      <svg
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <circle
          cx="12"
          cy="8"
          r="3.25"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
          d="M6.8475 19.25H17.1525C18.2944 19.25 19.174 18.2681 18.6408 17.2584C17.8563 15.7731 16.068 14 12 14C7.93201 14 6.14367 15.7731 5.35924 17.2584C4.82597 18.2681 5.70558 19.25 6.8475 19.25Z"
        />
      </svg>
    );
  } else if (type === "send") {
    return (
      <svg
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4.75 19.25L12 4.75L19.25 19.25L12 15.75L4.75 19.25Z"
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 15.5V12.75"
        />
      </svg>
    );
  } else if (type === "back") {
    return (
      <svg
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M13.25 8.75L9.75 12L13.25 15.25"
        />
      </svg>
    );
  } else if (type === "checkmark_old") {
    return (
      <svg
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"
        />
      </svg>
    );
  } else if (type === "lock") {
    return (
      <svg
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M5.75 11.75C5.75 11.1977 6.19772 10.75 6.75 10.75H17.25C17.8023 10.75 18.25 11.1977 18.25 11.75V17.25C18.25 18.3546 17.3546 19.25 16.25 19.25H7.75C6.64543 19.25 5.75 18.3546 5.75 17.25V11.75Z"
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M7.75 10.5V10.3427C7.75 8.78147 7.65607 7.04125 8.74646 5.9239C9.36829 5.2867 10.3745 4.75 12 4.75C13.6255 4.75 14.6317 5.2867 15.2535 5.9239C16.3439 7.04125 16.25 8.78147 16.25 10.3427V10.5"
        />
      </svg>
    );
  } else if (type == "login") {
    return (
      <svg width={width} height={height} fill="none" viewBox="0 0 24 24">
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M9.75 8.75L13.25 12L9.75 15.25"
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M9.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H9.75"
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M13 12H4.75"
        />
      </svg>
    );
  } else if (type == "incognito") {
    return (
      <svg width={width} height={height} fill="none" viewBox="0 0 24 24">
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M13.25 16.75h-2.5m-6-4s.409-.354 1.25-.764m0 0c1.16-.564 3.139-1.236 6-1.236s4.84.672 6 1.236m-12 0 .564-5.442a2 2 0 0 1 1.99-1.794h6.893a2 2 0 0 1 1.989 1.794L18 11.986m0 0c.841.41 1.25.764 1.25.764m-9 4.25a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm8 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
        ></path>
      </svg>
    );
  } else if (type === "login") {
    return (
      <svg
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
        className={className}
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M9.75 8.75L13.25 12L9.75 15.25"
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M9.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H9.75"
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M13 12H4.75"
        />
      </svg>
    );
  } else if (type === "checkmark") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M4 8.5L6.5 11L12.5 5"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="square"
          stroke-linejoin="round"
        />
      </svg>
    );
  } else if (type === "link") {
    return (
      <svg width="16" height="16" fill="none" viewBox="0 0 24 24">
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M16.75 13.25L18 12C19.6569 10.3431 19.6569 7.65685 18 6V6C16.3431 4.34315 13.6569 4.34315 12 6L10.75 7.25"
          className={className}
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M7.25 10.75L6 12C4.34315 13.6569 4.34315 16.3431 6 18V18C7.65685 19.6569 10.3431 19.6569 12 18L13.25 16.75"
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M14.25 9.75L9.75 14.25"
        />
      </svg>
    );
  } else if (type === "externalLink") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M7.16634 3.33325H4.49967C4.13148 3.33325 3.83301 3.63173 3.83301 3.99992V11.9999C3.83301 12.3681 4.13148 12.6666 4.49967 12.6666H12.4997C12.8679 12.6666 13.1663 12.3681 13.1663 11.9999V9.33325"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.8333 6.00008L13.8333 2.66675H10.5"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.16699 7.33341L13.8337 2.66675"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  } else if (type === "comment") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M15.6416 8.49771L15.6416 8.49901C15.6444 9.59348 15.3887 10.6731 14.8953 11.6501L14.8943 11.652C14.3087 12.8238 13.4083 13.8094 12.2941 14.4985C11.1799 15.1875 9.89588 15.5527 8.58584 15.5533L8.58473 15.5533C7.49027 15.5561 6.41061 15.3004 5.43369 14.807L5.24776 14.713L5.05015 14.7789L1.88085 15.8353C1.48998 15.9656 1.11811 15.5938 1.2484 15.2029L2.30483 12.0336L2.3707 11.836L2.27679 11.6501C1.78334 10.6731 1.52763 9.59348 1.53049 8.49901L1.53049 8.4979C1.531 7.18786 1.89623 5.90383 2.58527 4.78963C3.27431 3.67544 4.25995 2.77508 5.43179 2.18942L5.43179 2.18942L5.43369 2.18846C6.41061 1.69502 7.49027 1.43931 8.58473 1.44216H8.58603H9.01646C10.7413 1.54065 12.3699 2.27018 13.5917 3.49203C14.8136 4.71389 15.5431 6.34244 15.6416 8.06728L15.6416 8.49771Z"
          stroke="currentColor"
          strokeWidth={1.5}
        />
      </svg>
    );
  } else if (type === "mention") {
    return (
      <svg width="16" height="16" fill="none" viewBox="0 0 24 24">
        <circle
          cx="12"
          cy="12"
          r="3.25"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          className={className}
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M12 19.25C7.99594 19.25 4.75 16.0041 4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C18.8125 4.75 19.25 9.125 19.25 12V13.25C19.25 14.3546 18.3546 15.25 17.25 15.25V15.25C16.1454 15.25 15.25 14.3546 15.25 13.25V8.75"
        />
      </svg>
    );
  } else if (type === "jira") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M8 1L7.65664 1.34336L1.68672 7.31328C1.31162 7.6885 1.31162 8.3115 1.68672 8.68672L8 15L8.34336 14.6566L14.3133 8.68672C14.6884 8.3115 14.6884 7.6885 14.3133 7.31328L8 1ZM8.02561 2.39905L13.6266 8L9.33076 12.2958C9.31939 12.0049 9.27 11.7303 9.18943 11.4886C9.04583 11.0578 8.83177 10.7053 8.65447 10.4604L11.1149 8L8.34336 5.22846C8.34336 5.22846 7.93439 4.80966 7.73252 4.20407C7.56043 3.6878 7.50798 3.09368 8.02561 2.39905ZM6.66924 3.7042C6.68062 3.99511 6.73 4.26967 6.81057 4.51138C6.95417 4.94219 7.16824 5.29468 7.34553 5.53957L4.8851 8L5.22846 8.34336L7.65664 10.7715C7.65664 10.7715 8.06562 11.1903 8.26748 11.7959C8.43957 12.3122 8.49203 12.9063 7.97439 13.6009L2.37344 8L6.66924 3.7042ZM8 6.25854L9.74147 8L8 9.74146L6.25854 8L8 6.25854Z"
          fill="currentColor"
        />
      </svg>
    );
  } else if (type === "bigBlueComment") {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 53 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <g filter="url(#filter0_dd_323_421)">
          <path
            d="M14.6074 22.248C14.6074 15.6206 19.98 10.248 26.6074 10.248V10.248C33.2348 10.248 38.6074 15.6206 38.6074 22.248V22.248C38.6074 28.8755 33.2348 34.248 26.6074 34.248V34.248H20.6074H14.6074V28.248V22.248V22.248Z"
            fill="currentColor"
          />
          <path
            className="bigCommentStroke"
            d="M13.6074 34.248V35.248H14.6074H20.6074H26.6074C33.7871 35.248 39.6074 29.4277 39.6074 22.248C39.6074 15.0683 33.7871 9.24805 26.6074 9.24805C19.4277 9.24805 13.6074 15.0683 13.6074 22.248V28.248V34.248Z"
            stroke="white"
            stroke-width="2"
          />
        </g>
        <defs>
          <filter
            id="filter0_dd_323_421"
            x="0.607422"
            y="0.248047"
            width="52"
            height="52"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_323_421"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_323_421"
              result="effect2_dropShadow_323_421"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_323_421"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  } else if (type == "menu") {
    return (
      <svg width={width} height={height} fill="none" viewBox="0 0 24 24">
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M4.75 5.75H19.25"
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M4.75 18.25H19.25"
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M4.75 12H19.25"
        />
      </svg>
    );
  } else {
    return null;
  }
}

export default Icon;
