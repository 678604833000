import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
var parser = require("ua-parser-js");

const currentUA = parser(window.navigator.userAgent);
const isMobile = currentUA.device.type == "mobile" || currentUA.browser.name == "Safari";

const firebaseConfig = {
  apiKey: "AIzaSyBDHaVkRHK-hMRkUr2AiipaqiO3OYZTGkY",
  authDomain: isMobile ? "crit.ly" : "crit-tool.firebaseapp.com",
  projectId: "crit-tool",
  storageBucket: "crit-tool.appspot.com",
  messagingSenderId: "45434266791",
  appId: "1:45434266791:web:f4558409e7b8f80a556782",
  measurementId: "G-2DQ8ZTFW48",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const storage = getStorage();
const auth = getAuth();
const functions = getFunctions();

// const getAuthStatus = () => {
//   const getGoogleAccessToken = httpsCallable(
//     functions,
//     "getAccessToken"
//   );
//   getGoogleAccessToken({})
//     .then((result) => {
//       if (result && result.data && result.data.access_token) {
//         chrome.storage.local
//           .set({ googleAuthToken: result.data.access_token })
//           .then(() => {
//             sendResponse({ signedIn: true, currentUser: auth.currentUser });
//           });
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//       sendResponse({ signedIn: false });
//     });
// };

export { firebaseApp, db, storage, auth, functions };
