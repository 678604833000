import {
  doc,
  getDoc,
  addDoc,
  collection,
  onSnapshot,
  query,
  orderBy,
  where,
  limit,
  or,
  documentId,
  collectionGroup,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useParams, redirect, useSearchParams } from "react-router-dom";
import React, { useState, useEffect, useMemo } from "react";
import ImageViewer from "./ImageViewer";
import { db, storage, auth, functions } from "./shared/firebase";
import { Button } from "./components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "./components/ui/avatar";
//import TimeAgo from "timeago-react";
import Moment from "react-moment";
import "moment-timezone";
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

import { Link2Icon, RulerSquareIcon, LaptopIcon } from "@radix-ui/react-icons";

import { Link, useNavigate } from "react-router-dom";

import UserNav from "./components/ui/usernav";
import { Combobox } from "./components/ui/comboBox";
import Icon from "./components/ui/icons";
import * as constants from "./shared/constants";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipArrow,
} from "./components/ui/tooltip";

import "./App.css";
import Logo from "./critly_logo.svg";
import { motion, AnimatePresence } from "framer-motion";

import { Helmet } from "react-helmet";

var parser = require("ua-parser-js");
var domainParser = require("tld-extract");

const timeAgoFormatConfig = {
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: "ago",
  suffixFromNow: "from now",
  seconds: "%ds",
  minutes: "%dm",
  hour: "%dh",
  hours: "%dh",
  day: "%dd",
  days: "%dd",
  week: "%dw",
  weeks: "%dw",
  month: "%dmth",
  month: "%dmth",
  year: "%dy",
  years: "%dy",
  wordSeparator: " ",
};

const timeAgoFormatter = buildFormatter(timeAgoFormatConfig);

function App() {
  const { domain, id } = useParams();
  const [urlParams] = useSearchParams();
  const [site, setSite] = useState(null);
  const [critId, setCritId] = useState(null);
  const [critData, setCritData] = useState({});
  const [critUserData, setCritUserData] = useState({});
  const [critImageUrl, setCritImageUrl] = useState("");
  const [fullImageUrl, setFullImageUrl] = useState("");
  const [ua, setUa] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);
  const [replies, setReplies] = useState([]);
  const [crits, setCrits] = useState([]);
  const [user, setUser] = useState({});
  const [sites, setSites] = useState([]);
  const [filterMode, setFilterMode] = useState(
    domain == null ? "recent" : "site"
  );
  const navigate = useNavigate();

  const currentUA = parser(window.navigator.userAgent);
  const isMobile = currentUA.device.type == "mobile";

  useEffect(() => {
    setSite(domain);
    setCritId(id);
  }, [domain, id]);

  useEffect(() => {
    onAuthStateChanged(auth, (userSnap) => {
      if (userSnap) {
        setUser(userSnap.toJSON());
      } else {
        setUser({});
      }
    });
  }, []);

  // useEffect(() => {
  //   if(site == "" && typeof site == "undefined" && critId == "" && typeof critId == "undefined") {
  //     setFilterMode("recent");
  //   } else {
  //     setFilterMode("site");
  //   }
  // }, [critId, site]);

  useEffect(() => {
    if (critId == null) return;
    if (site == null) return;

    console.log("critId use effect", critId, site);

    var data = {};
    getDoc(doc(db, getDocPath()))
      .then((docSnap) => {
        if (docSnap.exists()) {
          data = docSnap.data();

          data["ua"] = parser(data.userAgent);
          data["id"] = critId;
          data["site"] = getSiteName();
          data["isCloudinary"] =
            data.mediaHost && data.mediaHost == "cloudinary";

          console.log("got crit doc", data);

          setCritData(data);
          setCritImageUrl(getCropImgUrl(critId, data["isCloudinary"]));
          setFullImageUrl(getBgImgUrl(critId, data["isCloudinary"]));
          setUa(parser(critData.userAgent));

          if (data.user) {
            return getDoc(doc(db, "users", data.user));
          } else {
            setCritUserData({});
            setCrits((crits) => [...crits, data]);
            return true;
          }
        } else {
          console.log("No such document!");
        }
      })
      .then((userSnap) => {
        setCritUserData(userSnap.data());

        data["userData"] = userSnap.data();
        console.log(data);
        setCrits((crits) => [...crits, data]);
      })
      .catch((error) => {
        console.log(error);
      });

    setReplies([]);
    const q = query(
      collection(db, getDocPath() + "replies"),
      orderBy("createdDateTime")
    );
    onSnapshot(q, (querySnapshot) => {
      var tmp = [];
      querySnapshot.forEach(async (replyDoc) => {
        var replyData = replyDoc.data();

        if (replyData.user) {
          var userTmp = await getDoc(doc(db, "users", replyData.user));
          replyData["userData"] = userTmp.data();
        }
        tmp.push(replyData);
        setReplies(tmp);
        //console.log("replies", tmp);
      });
    });
  }, [critId]);

  useEffect(() => {
    console.log("sites use effect");

    const sitesQuery = query(collection(db, getSitesPath()), limit(50));
    onSnapshot(sitesQuery, (querySnapshot) => {
      var tmp = [];
      querySnapshot.forEach(async (siteDoc) => {
        if (siteDoc.id == getSiteName()) return;
        var siteData = siteDoc.data();
        if (!siteData.domainName) return;
        siteData["value"] = siteDoc.id;
        siteData["label"] = siteDoc.id;
        siteData["img"] = getSiteIcon(siteDoc.id);

        tmp.push(siteData);
        setSites(tmp);
      });
    });
  }, []);

  useEffect(() => {
    console.log("site, user, filterMode use effect", site, user, filterMode);

    if (
      site != null &&
      site !== "" &&
      typeof site !== "undefined" &&
      filterMode == "site"
    ) {
      /*
      1. public crits
      2. if you're signed in, 
        a. crits you're mentioned in
        b. "private" crits and your email domain matches the url domain
        c. crits posted by you
      */
      var queryCrits = query(
        collection(db, getSitePath()),
        or(where("privacy", "==", "public")),
        orderBy("createdDateTime", "desc")
      );

      if (user?.uid) {
        if (isEmailDomainSameAsCritDomain()) {
          queryCrits = query(
            collection(db, getSitePath()),
            or(
              where("privacy", "==", "public"),
              where("privacy", "==", "private"),
              where("user", "==", user?.uid),
              where("taggedEmails", "array-contains", user.email)
            ),
            orderBy("createdDateTime", "desc")
          );
        } else {
          queryCrits = query(
            collection(db, getSitePath()),
            or(
              where("privacy", "==", "public"),
              where("user", "==", user?.uid),
              where("taggedEmails", "array-contains", user.email)
            ),
            orderBy("createdDateTime", "desc")
          );
        }
      }

      onSnapshot(queryCrits, (querySnapshot) => {
        var tmp = [];
        querySnapshot.forEach(async (critDoc) => {
          if (!critDoc || !critDoc.data()) return;
          //if (critDoc.id == critId) return;
          var critDocData = critDoc.data();
          critDocData["id"] = critDoc.id;
          tmp.push(critDocData);
          if (critDocData.user && typeof critDocData.user === "string") {
            var userTmp = await getDoc(doc(db, "users", critDocData.user));
            critDocData["userData"] = userTmp.data();
          }
          setCrits((crits) => [...crits, critDocData]);
          console.log(tmp);
        });
      });
    } else if (filterMode == "recent") {
      var limitAmount = critId == null ? 1 : 10;
      const sitesQuery = query(
        collectionGroup(db, "crits"),
        or(where("privacy", "==", "public")),
        orderBy("createdDateTime", "desc"),
        limit(limitAmount)
      );
      console.log(sitesQuery);
      onSnapshot(sitesQuery, (querySnapshot) => {
        var tmp = [];
        querySnapshot.forEach(async (critDoc) => {
          console.log(critDoc.data());
          if (!critDoc || !critDoc.data()) return;
          //if (critDoc.id == critId) return;
          var critDocData = critDoc.data();
          critDocData["id"] = critDoc.id;
          tmp.push(critDocData);
          if (critDocData.user && typeof critDocData.user === "string") {
            var userTmp = await getDoc(doc(db, "users", critDocData.user));
            critDocData["userData"] = userTmp.data();
          }
          setCrits((crits) => [...crits, critDocData]);
          console.log(tmp);
        });
      });
    }
  }, [site, filterMode, user]);

  useEffect(() => {
    console.log("crits use effect", critId, filterMode, crits);
    if (critId == null && crits.length > 0) {
      var critDataTmp = crits[0];
      console.log(critDataTmp);

      setSite(getHost(critDataTmp.pageUrl));
      setCritId(critDataTmp.id);

      //navigate("/" + getHost(critDataTmp.pageUrl) + "/" + critDataTmp.id);
    }
  }, [crits]);

  // useEffect(() => {
  //   if (initialLoad) {
  //     if (site !== "" && typeof site !== "undefined") {
  //     } else {
  //     }
  //   }
  // }, [critId, user]);

  const siteCrits = useMemo(() => {
    return crits
      .filter((crit, i) => {
        return i === crits.findIndex((c) => crit.id === c.id);
      })
      .sort((a, b) => b.createdDateTime.seconds - a.createdDateTime.seconds);
  }, [crits]);

  const getHost = (url) => {
    console.log("get host", url);
    var siteHostArr = new URL(url).hostname.split(".");
    console.log(siteHostArr);
    if (siteHostArr.length > 2) siteHostArr.shift();
    return siteHostArr.join(".");
  };

  const getDocPath = () => {
    var siteCleaned = site.replace("-dot-", ".");
    return "/sites/" + siteCleaned + "/crits/" + critId + "/";
  };

  const getSiteName = () => {
    if (site == null) return "";

    return site.replace("-dot-", ".");
  };

  const getSiteIcon = (domain) => {
    return "https://www.google.com/s2/favicons?domain=" + domain + "&sz=32";
  };

  const getSitePath = () => {
    var siteCleaned = getSiteName();
    return "/sites/" + siteCleaned + "/crits/";
  };

  const getSitesPath = () => {
    return "/sites/";
  };

  const getBgImgUrl = (id, isCloudinary = false) => {
    if (isCloudinary)
      return (
        "https://res.cloudinary.com/dwpxmif3x/image/upload/e_blur:576,q_auto:good/" +
        id +
        ".jpg"
      );
    return "https://lalanistudio-224097265.imgix.net/" + id + ".png?blur=70";
  };

  const getCropImgUrl = (id, isCloudinary = false) => {
    if (isCloudinary)
      return (
        "https://res.cloudinary.com/dwpxmif3x/image/upload/" +
        id +
        ".cropped.png"
      );
    return "https://lalanistudio-224097265.imgix.net/" + id + ".cropped.png";
  };

  const submitReply = async (comment) => {
    const collectionRef = collection(db, getDocPath() + "replies");

    try {
      var dateTemp = new Date();
      const docRef = await addDoc(collectionRef, {
        comment: comment,
        createdDateTime: dateTemp,
        user: user.uid || null,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const isEmailDomainSameAsCritDomain = () => {
    if (!(user && user.email)) return false;

    var emailFullDomain = user.email.split("@")[1];
    var emailDomainInfo = require("tld-extract").parse_host(emailFullDomain);
    if (emailDomainInfo && emailDomainInfo.domain == getSiteName()) return true;
    else return false;
  };

  const isViewable = (data) => {
    if (data.privacy) {
      if (data.privacy == "public") return true;
      else if (user && data.user == user?.uid && data.user) {
        return true;
      } else if (data.privacy == "private" && isEmailDomainSameAsCritDomain()) {
        return true;
      } else if (
        user &&
        typeof user.email != "undefined" &&
        data?.taggedEmails?.includes(user.email)
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      {sites && (
        <div
          className={
            (isMobile ? "flex flex-col" : "flex-row flex p-4 space-x-6") +
            " max-h-full h-screen w-screen bg-gray-900"
          }
        >
          <div
            className={
              "w-64 overflow-y-auto relative block space-y-6 scrollbar scrollbar-thumb-gray-800 scrollbar-track-gray-900 scrollbar-thin scrollbar-none " +
              (isMobile ? "hidden" : "")
            }
          >
            <div className="z-20 flex flex-row pt-3 pb-6 justify-between items-center align-middle sticky top-0 border-b border-slate-800 bg-gray-900">
              <Link to={"/"}>
                <img src={Logo} className="h-6" />
              </Link>
              <UserNav
                user={user}
                logout={() => {
                  auth.signOut();
                }}
                login={() => {
                  window.location = "/login?referrer=" + window.location;
                }}
              />
            </div>

            <div className="z-10 flex flex-col space-y-4 max-w-full w-full border-b border-slate-800 pb-6 sticky top-4 bg-gray-900">
              <div className="flex flex-col space-y-1 ">
                <span className="font-medium text-sm text-slate-200">
                  Critly simplifies feedback
                </span>
                <span className="text-sm text-slate-500">
                  Comment directly on any site
                </span>
              </div>
              <Link
                to={
                  "https://chromewebstore.google.com/detail/critly/abodjnpfbnnnhlnohdncncbokiiblgji?hl=en"
                }
                target="_blank"
                className="w-full"
              >
                <Button className="w-full" variant={"secondary"}>
                  Get Critly
                </Button>
              </Link>
            </div>

            {sites && sites.length > 0 && (
              <div className="flex flex-col space-y-3 max-w-full overflow-visible">
                <div className="mb-2 space-y-1">
                  <span className="text-sm text-slate-500">Browse Critlys</span>
                  <Combobox
                    searchPlaceholder="Search domains..."
                    options={[
                      {
                        value: "across the web",
                        label: "Across The Web",
                        iconType: "globe",
                      },
                      {
                        value: getSiteName(),
                        label: getSiteName(),
                        img: getSiteIcon(getSiteName()),
                      },
                      ...sites.filter((val) => val.value != getSiteName()),
                    ]}
                    img={
                      filterMode == "recent" ? null : getSiteIcon(getSiteName())
                    }
                    iconType={filterMode == "recent" ? "globe" : null}
                    value={
                      filterMode == "recent" ? "across the web" : getSiteName()
                    }
                    onChangeValue={(value) => {
                      console.log("value", value);
                      if (value == "across the web") {
                        window.location = "/";
                        return;
                      }

                      var siteInfo = sites.find((el) => el.value == value);

                      window.location = "/" + siteInfo.domainName;
                    }}
                  />
                </div>
                <div className="space-y-5 max-w-full overflow-visible">
                  {siteCrits &&
                    siteCrits.map((crit) => (
                      <Link
                        to={"/" + getHost(crit.pageUrl) + "/" + crit.id}
                        className={
                          (crit.id == critId
                            ? "border-primary"
                            : "border-transparent") +
                          " rounded-xl border-2 " +
                          (crit.id == critId ? "" : "hover:border-primary/50") +
                          " w-full p-1 block transition-all duration-150 active:border-blue-600"
                        }
                        key={crit.createdDateTime.seconds}
                      >
                        <div className="rounded-lg flex flex-col w-full overflow-clip">
                          <motion.div
                            style={{
                              backgroundImage:
                                "url('" +
                                getBgImgUrl(
                                  crit.id,
                                  crit.mediaHost &&
                                    crit.mediaHost == "cloudinary"
                                ) +
                                "')",
                            }}
                            className="bg-cover bg-center bg-no-repeat w-full h-32 min-w-[3rem] relative"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                          >
                            <div className="w-full h-full opacity-30 bg-black absolute" />
                            <div
                              style={{
                                backgroundImage:
                                  "url('" +
                                  getCropImgUrl(
                                    crit.id,
                                    crit.mediaHost &&
                                      crit.mediaHost == "cloudinary"
                                  ) +
                                  "')",
                                width:
                                  Math.ceil(
                                    100 *
                                      (crit.crop.width / crit.windowSize.width)
                                  ) + "%",
                                height:
                                  Math.ceil(
                                    100 *
                                      (crit.crop.height /
                                        crit.windowSize.height)
                                  ) + "%",
                                left:
                                  Math.ceil(
                                    100 * (crit.crop.x / crit.windowSize.width)
                                  ) + "%",
                                top:
                                  Math.ceil(
                                    100 * (crit.crop.y / crit.windowSize.height)
                                  ) + "%",
                              }}
                              className={
                                "bg-cover bg-center bg-no-repeat rounded-md absolute" +
                                " " +
                                (isViewable(crit) ? "" : "h6idden")
                              }
                            />
                          </motion.div>
                          <div className="p-3 bg-slate-800 space-y-3 text-slate-500">
                            <div
                              className={
                                "flex flex-row space-x-1 max-w-full" +
                                " " +
                                (isViewable(crit) ? "hidden" : "")
                              }
                            >
                              <Icon type="lock" />
                              <span className="text-xs font-semibold capitalize">
                                {crit.privacy}
                              </span>
                            </div>
                            <div
                              className={
                                "flex flex-row max-w-full overflow-x-hidden justify-between" +
                                " " +
                                (isViewable(crit) ? "" : "hidden")
                              }
                            >
                              <div className="flex-1 flex flex-row space-x-1">
                                <Avatar className="h-4 w-4">
                                  <AvatarImage
                                    src={
                                      crit.userData
                                        ? crit.userData.photoURL
                                        : constants.DEFAULT_USER_IMG
                                    }
                                  />
                                </Avatar>
                                <span className="text-xs font-medium text-ellipsis overflow-hidden truncate max-w-full text-slate-500">
                                  {crit.userData
                                    ? crit.userData.name
                                    : constants.DEFAULT_USER_NAME}
                                </span>
                              </div>
                              <div className="text-xs flex flex-row space-x-1">
                                <TooltipProvider delayDuration={250}>
                                  <Tooltip>
                                    <TooltipTrigger>
                                      <TimeAgo
                                        date={
                                          crit.createdDateTime.seconds * 1000
                                        }
                                        formatter={timeAgoFormatter}
                                      />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                      <div className="flex flex-col space-y-1 text-xs py-1">
                                        <Moment local unix format="LLLL">
                                          {crit.createdDateTime.seconds}
                                        </Moment>
                                      </div>
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>

                                {crit.privacy != "public" && (
                                  <TooltipProvider delayDuration={250}>
                                    <Tooltip>
                                      <TooltipTrigger>
                                        {crit.privacy == "private" && (
                                          <Icon type="lock" />
                                        )}
                                        {crit.privacy == "super private" && (
                                          <Icon type="incognito" />
                                        )}
                                      </TooltipTrigger>
                                      <TooltipContent>
                                        <div className="flex flex-col space-y-1 text-xs py-1">
                                          <p className="font-semibold capitalize">
                                            {crit.privacy}
                                          </p>
                                          <p>
                                            {crit.privacy == "private" &&
                                              "Only shared with you, anyone @mentioned, and peole with a @" +
                                                getSiteName() +
                                                " email"}
                                            {crit.privacy == "super private" &&
                                              "Only shared with you and anyone @mentioned"}
                                          </p>
                                        </div>
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                )}
                              </div>
                            </div>
                            <div
                              className={
                                "flex flex-col space-y-1 max-w-full overflow-x-hidden" +
                                " " +
                                (isViewable(crit) ? "" : "hidden")
                              }
                            >
                              <span className="text-sm text-ellipsis overflow-hidden truncate  max-w-full overflow-x-hidden text-slate-500">
                                {crit.comment}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            )}

            <div className="sticky bottom-0 h-12 w-full block bg-gradient-to-b from-transparent to-gray-900 "></div>
          </div>
          <div
            className={
              "rounded-xl border border-slate-800 w-full overflow-hidden " +
              (isMobile ? "flex flex-col" : "")
            }
          >
            {fullImageUrl && critData && critData.crop && critData.windowSize && (
              <>
                <Helmet>
                  <title>Feedback for {getSiteName()}</title>
                  {/* <meta
                    property="og:description"
                    content={critData.comment}
                  />
                  <meta
                    property="og:image"
                    content={critImageUrl}
                  /> */}
                </Helmet>

                <ImageViewer
                  src={critImageUrl}
                  bgSrc={fullImageUrl}
                  crop={critData.crop}
                  size={critData.windowSize}
                  data={critData}
                  user={critUserData}
                  onReplySubmit={submitReply}
                  replies={replies}
                  authUser={user}
                  id={critId}
                  viewable={isViewable(critData)}
                  targetEmail={urlParams.get("email")}
                />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default App;
