import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";
import { Button } from "./button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "./dropdown-menu";
import { Link, useNavigate } from "react-router-dom";

import Icon from "./icons";

import * as constants from "../../shared/constants";

const UserNav = ({ user = {}, logout = null, login = null }) => {
  return (
    <>
      {(true || user.uid) && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div>
              {user.uid && (
                <img
                  src={user.photoURL || constants.DEFAULT_USER_IMG}
                  className="w-6 h-6 block rounded-xl cursor-pointer"
                  referrerPolicy="no-referrer"
                />
              )}
              {!user.uid && (
                <div
                  className="text-slate-600 hover:text-slate-400 cursor-pointer"
                  onClick={login}
                >
                  <Icon type="menu" width={24} height={24} />
                </div>
              )}
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56" align="end" forceMount>
            {user.uid && (
              <>
                <DropdownMenuLabel className="font-normal">
                  <div className="flex flex-col space-y-1">
                    <p className="text-sm font-medium leading-none">
                      {user.displayName || constants.DEFAULT_USER_NAME}
                    </p>
                    <p className="text-xs leading-none text-muted-foreground">
                      {user.email || "You're using Critly anonymously"}
                    </p>
                  </div>
                </DropdownMenuLabel>

                <DropdownMenuSeparator />
              </>
            )}

            {user.uid && (
              <DropdownMenuItem onClick={logout}>Sign out</DropdownMenuItem>
            )}
            {!user.uid && (
              <DropdownMenuItem onClick={login}>
                <div className="flex justify-between space-x-2 items-center">
                  <Avatar>
                    <AvatarImage
                      src="https://user-images.githubusercontent.com/1770056/58111071-c2941c80-7bbe-11e9-8cac-1c3202dffb26.png"
                      alt="google sign in"
                    />
                  </Avatar>
                  <span>Sign in with Google</span>
                </div>
              </DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              {/* <DropdownMenuItem>FAQ</DropdownMenuItem> */}
              <Link to={"/privacy"}>
                <DropdownMenuItem>Privacy</DropdownMenuItem>
              </Link>
              {/* <DropdownMenuItem>Contact</DropdownMenuItem> */}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      {false && !user.uid && (
        <div
          className="text-slate-800 hover:text-slate-600 cursor-pointer"
          onClick={login}
        >
          <Icon type="login" width={24} height={24} />
        </div>
      )}
    </>
  );
};

export default UserNav;
