import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { motion } from "framer-motion";
import { PaperPlaneIcon } from "@radix-ui/react-icons";
import { cn } from "../../lib/utils";
import Icon from "./icons";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-lg text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground shadow hover:bg-primary/75 border border-transparent",
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline:
          "border text-primary-foreground border-muted-foreground bg-transparent shadow-sm hover:border-accent",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-primary hover:text-primary-foreground",
        ghost: "hover:bg-accent hover:text-accent-foreground text-slate-500 font-normal",
        link: "text-primary underline-offset-4 hover:underline",
        send: "p-0 h-auto w-auto rounded-xl hover:bg-accent"
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-lg px-2 text-xs",
        lg: "h-10 rounded-lg px-8",
        icon: "h-9 w-9 rounded-xl",
        iconSm: "h-8 w-8 rounded-2xl",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

const SendButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = "send" , size = "iconSm", asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        <motion.button
          whileTap={{ scale: 0.90, opacity: 0.8 }}
          whileHover={{
            scale: 1.06,
            rotate: 90,
          }}
          animate={{
            opacity: 1,
            rotate: 45,
            scale: 1,
          }}
          initial={{
            opacity: 0,
            rotate: 0,
            scale: 0,
          }}
          transition={{
            type: "spring",
            bounce: 0.5,
          }}
          className="w-full h-full"
        >
          <Icon type="send" className="w-full h-full text-slate-500 p-2 hover:text-accent-foreground" />
        </motion.button>
      </Comp>
    );
  }
);
SendButton.displayName = "SendButton";

export { Button, SendButton, buttonVariants };
