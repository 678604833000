import "./App.css";
import Logo from "./critly_logo.svg";

function Home() {
  return (
    <div className="flex flex-col styledText justify-center items-center align-middle h-screen bg-primary text-accent">
      <img src={Logo} width={120} className="mb-3" />
      <p className="p-6 opacity-70">Feedback simplified. Comment directly on any site.</p>
      <p className=" opacity-20 text-xs">Coming soon...</p>
    </div>
  );
}

export default Home;
