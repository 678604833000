/* global chrome */
import React, { useEffect, useRef, useState, useCallback } from "react";
import Moment from "react-moment";
import "moment-timezone";
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { DesktopIcon } from "@radix-ui/react-icons";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverAnchor,
} from "./components/ui/popover";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./components/ui/command";

import {
  cn,
  getCaretCoordinates,
  getCurrentWord,
  replaceWord,
  getWords,
  replaceLast,
  isEmail,
  getPrevWord,
} from "./lib/utils";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipArrow,
} from "./components/ui/tooltip";

import { Skeleton } from "./components/ui/skeleton";

import { motion, AnimatePresence } from "framer-motion";
import { Avatar, AvatarFallback, AvatarImage } from "./components/ui/avatar";
import { functions } from "./shared/firebase";
import { httpsCallable } from "firebase/functions";
import * as constants from "./shared/constants";
import { Button, SendButton } from "./components/ui/button";
import Icon from "./components/ui/icons";
var parser = require("ua-parser-js");

const timeAgoFormatConfig = {
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: "ago",
  suffixFromNow: "from now",
  seconds: "%ds",
  minutes: "%dm",
  hour: "%dh",
  hours: "%dh",
  day: "%dd",
  days: "%dd",
  week: "%dw",
  weeks: "%dw",
  month: "%dmth",
  month: "%dmth",
  year: "%dy",
  years: "%dy",
  wordSeparator: " ",
};

const timeAgoFormatter = buildFormatter(timeAgoFormatConfig);

const styles = {
  container: {
    overflow: "hidden",
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    // border: "1px solid #ddd",
    position: "relative",
    background: "#fff",
    cursor: "grab",
    userSelect: "none",
    touchCallout: "none",
    userDrag: "none",
    background: "black",
  },
  content: {
    display: "block",
    width: "100%",
    cursor: "grab",
    userSelect: "none",
    touchCallout: "none",
    userDrag: "none",
  },
  image: {
    display: "block",
    position: "absolute",
    //width: "auto",
    userSelect: "none",
    touchCallout: "none",
    userDrag: "none",
    //borderRadius: "12px",
    maxWidth: "none",
  },
  imageBg: {
    display: "block",
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    userSelect: "none",
    touchCallout: "none",
    userDrag: "none",
    //filter: 'blur(6px)',
    opacity: 0.5,
    //borderRadius: "24px",
    maxWidth: "none",
  },
  comment: {
    position: "absolute",
  },
};

const REPLY_INPUT_INITIAL_HEIGHT = 20;
const INITIAL_DEFAULT_SCALE = 0.95;

const currentUA = parser(window.navigator.userAgent);
const isMobile = currentUA.device.type == "mobile";

const ImageViewer = ({
  id,
  src,
  bgSrc,
  crop = { x: 0, y: 0, width: 0, height: 0 },
  size = { width: 0, height: 0 },
  data = {},
  user = {},
  alt = "Zoomable",
  onReplySubmit,
  replies = [],
  authUser = {},
  viewable = true,
  targetEmail = "",
}) => {
  console.log("is viewable", viewable);

  const computeInitialScale = (size, containerSize) => {
    var newScale = INITIAL_DEFAULT_SCALE;

    if (
      containerSize.width * INITIAL_DEFAULT_SCALE >= size.width &&
      containerSize.height * INITIAL_DEFAULT_SCALE >= size.height
    ) {
      newScale = 1;
    } else {
      newScale = Math.min(
        Math.min(
          containerSize.width / size.width,
          containerSize.height / size.height
        ) * 0.98,
        INITIAL_DEFAULT_SCALE
      );
    }

    return newScale;

    // const cropWidth = crop.width || 1;
    // const cropHeight = crop.height || 1;
    // const scaleX = containerSize.width / cropWidth;
    // const scaleY = containerSize.height / cropHeight;
    // return Math.min(scaleX, scaleY);
  };

  const computeInitialPosition = (size, containerSize, scale) => {
    var x = 0;
    var y = 0;

    //containerRef.current.getBoundingClientRect();

    var newSizeH = size.height * scale;
    //y = -1 * ((size.height - newSizeH) / 2);
    //y = y + ((containerSize.height - newSizeH)/2);

    var newSizeW = size.width * scale;
    //x = -1 * ((size.width - newSizeW) / 2);
    //x = x + ((containerSize.width - newSizeW)/2);

    // if (!(scale == 1 && crop.width + crop.x <= containerSize.width)) {
    //   const centerX = containerSize.width / 2;
    //   const cropCenterX = crop.x + crop.width / 2;
    //   if (centerX >= cropCenterX) {
    //     x = centerX - cropCenterX * scale;
    //   } else {
    //     x = containerSize.width - (crop.x + crop.width) * scale;
    //     x = x - 12 + crop.width * scale <= containerSize.width ? x - 12 : x;
    //   }
    // }

    // if (!(scale == 1 && crop.height + crop.y <= containerSize.height)) {
    //   const centerY = containerSize.height / 2;
    //   const cropCenterY = crop.y + crop.height / 2;
    //   y = 0; //centerY - cropCenterY * scale;

    //   // if(centerY >= cropCenterY) {
    //   //   y = 0;
    //   // } else {
    //   //   y = containerSize.height - ((crop.y + crop.height) * scale);
    //   //   y = y - 12 + (crop.height * scale) <= containerSize.height ? y - 12 : y;
    //   // }
    // }

    return {
      x: x,
      y: y,
    };
  };

  const windowSize = {
    width: window.innerWidth * (isMobile ? 1 : 0.78),
    height: window.innerHeight * (isMobile ? 0.5 : 0.98),
  };

  const imageRef = useRef(null);
  const containerRef = useRef(null);
  const commentRef = useRef(null);
  const textareaReplyRef = useRef(null);
  const replyButtonRef = useRef(null);
  const repliesPopoverRef = useRef(null);

  const initialScale = computeInitialScale(size, windowSize);
  const initialPosition = computeInitialPosition(
    size,
    windowSize,
    initialScale
  );

  const [isPanning, setIsPanning] = useState(false);
  const [isZooming, setIsZooming] = useState(false);
  const [initialX, setInitialX] = useState(0);
  const [initialY, setInitialY] = useState(0);
  const [imgStartX, setImgStartX] = useState(0);
  const [imgStartY, setImgStartY] = useState(0);

  const [imgX, setImgX] = useState(initialPosition.x);
  const [imgY, setImgY] = useState(initialPosition.y);
  const [scale, setScale] = useState(initialScale);
  const [showImages, setShowImages] = useState(false);

  const [startScale, setStartScale] = useState(initialScale);
  const [initialDistance, setInitialDistance] = useState(null);
  const [deltaTmp, setDeltaTmp] = useState("");

  const [showComment, setShowComment] = useState(false);
  const [showCommentBubble, setShowCommentBubble] = useState(false);
  const [commentLoc, setCommentLoc] = useState(data.commentLoc);

  const [contacts, setContacts] = useState([]);
  const [contactsByEmail, setContactsByEmail] = useState([]);
  const [foundContacts, setFoundContacts] = useState([]);
  const [noContactsPermissions, setNoContactsPermissions] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverValue, setPopoverValue] = useState("");
  const [autoSuggestValue, setAutoSuggestValue] = useState("");
  const [commentingState, setCommentingState] = useState("initial");

  const autoSuggestRef = useRef(null);

  const [commentBoxHeight, setCommentBoxHeight] = useState(
    REPLY_INPUT_INITIAL_HEIGHT
  );
  const [replyValue, setReplyValue] = useState("");

  const [firstTimeImgLoad, setFirstTimeImgLoad] = useState(false);

  const ZOOM_SPEED = 0.001;
  const ZOOM_SENSITIVITY = 0.01;

  let image;
  let container;
  let comment;

  let wheelEventEndTimeout = null;

  let _isPanning = false;
  let _initialX = initialPosition.x;
  let _initialY = initialPosition.y;
  let _startX = initialPosition.x;
  let _startY = initialPosition.y;
  let _initialDistance = 0;
  let _startScale = initialScale;
  let _scale = initialScale;

  useEffect(() => {
    console.log("replies", replies);
  }, [replies]);

  useEffect(() => {
    let _initialScale = computeInitialScale(size, windowSize);
    let _initialPosition = computeInitialPosition(
      size,
      windowSize,
      _initialScale
    );

    console.log("src updated", data, _initialPosition, _initialScale);

    _isPanning = false;
    _initialX = _initialPosition.x;
    _initialY = _initialPosition.y;
    _startX = _initialPosition.x;
    _startY = _initialPosition.y;
    _initialDistance = 0;
    _startScale = _initialScale;
    _scale = _initialScale;
    setCommentLoc(data.commentLoc);
    setImgX(_initialPosition.x);
    setImgY(_initialPosition.y);
    setScale(_initialScale);
  }, [src]);

  useEffect(() => {
    setShowComment(false);
    setShowCommentBubble(false);
    setShowImages(false);
  }, [id]);

  useEffect(() => {
    console.log("setFirstTimeImgLoad", firstTimeImgLoad);
    if (firstTimeImgLoad) {
      // setImgX(_initialX);
      // setImgY(_initialY);
      // setScale(_scale);
      //setShowComment(true);

      var imageRect = imageRef.current.getBoundingClientRect();
      var containerRect = containerRef.current.getBoundingClientRect();
      var newSizeW = size.width * _scale;
      setImgX(
        containerRect.x - imageRect.left + (containerRect.width - newSizeW) / 2
      );

      var newSizeH = size.height * _scale;
      setImgY(
        containerRect.y - imageRect.top + (containerRect.height - newSizeH) / 2
      );

      setShowImages(true);

      console.log(
        "image rect",
        imageRect,
        containerRect.x - imageRect.x + (containerRect.width - newSizeW) / 2
      );
    }
    setFirstTimeImgLoad(false);
  }, [firstTimeImgLoad]);

  useEffect(() => {
    if (authUser.uid) {
      fetchContacts();
    }
  }, []);

  useEffect(() => {
    scrollPopoverToBottom();
  }, [replies.length]);

  const scrollPopoverToBottom = () => {
    repliesPopoverRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const endPan = () => {
    setIsPanning(false);
    _isPanning = false;
    image.style.cursor = "grab";
    container.style.cursor = "grab";
  };

  const startPan = (e) => {
    image = imageRef.current;
    container = containerRef.current;
    setDeltaTmp("start panning");

    e.preventDefault();
    e.stopPropagation();

    const point = e.type === "touchstart" ? e.touches[0] : e;
    setInitialX(point.clientX);
    setInitialY(point.clientY);
    _initialX = point.clientX;
    _initialY = point.clientY;

    const matrix = window.getComputedStyle(image).transform.split(", ");
    setImgStartX(parseFloat(matrix[4]) || 0);
    setImgStartY(parseFloat(matrix[5]) || 0);
    _startX = parseFloat(matrix[4]) || 0;
    _startY = parseFloat(matrix[5]) || 0;

    setIsPanning(true);
    _isPanning = true;
    container.style.cursor = "grabbing";
    image.style.cursor = "grabbing";
  };

  const doPan = (e) => {
    image = imageRef.current;
    container = containerRef.current;
    if (!_isPanning) return;

    e.preventDefault();
    e.stopPropagation();
    const point = e.type === "touchmove" ? e.touches[0] : e;
    let x = 0;
    let y = 0;

    if (e.deltaX || e.deltaY) {
      const matrix = window.getComputedStyle(image).transform.split(", ");
      x = (parseFloat(matrix[4]) || 0) + e.deltaX;
      y = (parseFloat(matrix[5]) || 0) + e.deltaY;
    } else {
      x = _startX + (point.clientX - _initialX);
      y = _startY + (point.clientY - _initialY);
    }

    setImgX(x);
    setImgY(y);
  };

  const getDistanceBetweenTouches = (e) => {
    const [touch1, touch2] = e.touches;
    return Math.sqrt(
      Math.pow(touch2.clientX - touch1.clientX, 2) +
        Math.pow(touch2.clientY - touch1.clientY, 2)
    );
  };

  const handleWheel = (e) => {
    image = imageRef.current;
    container = containerRef.current;

    e.preventDefault();

    clearTimeout(wheelEventEndTimeout);
    wheelEventEndTimeout = setTimeout(() => {
      endPan();
    }, 100);

    if (
      Math.abs(Math.round(e.wheelDeltaY / 3)) === Math.abs(e.deltaY) ||
      Math.abs(Math.round(e.wheelDeltaY / 6)) === Math.abs(e.deltaY)
    ) {
      const matrix = window.getComputedStyle(image).transform.split(", ");
      let x = (parseFloat(matrix[4]) || 0) + e.deltaX;
      let y = (parseFloat(matrix[5]) || 0) + e.deltaY;
      setImgX(x);
      setImgY(y);
      setIsPanning(true);
    } else {
      if (e.deltaY > 0) {
        _scale = adjustScale(_scale - ZOOM_SPEED * 10); // - (prevScale) => adjustScale(prevScale - ZOOM_SPEED * 10);
      } else {
        _scale = adjustScale(_scale + ZOOM_SPEED * 10); //(prevScale) => adjustScale(prevScale + ZOOM_SPEED * 10);
      }
      setScale(_scale);
    }
  };

  const handlePinchZoom = (e) => {
    const distance = getDistanceBetweenTouches(e);
    if (_initialDistance && distance) {
      // Check to ensure initialDistance and distance are not zero
      const rawZoomFactor = distance - _initialDistance;

      // Adjust the zoom factor using sensitivity
      const adjustedZoomFactor = rawZoomFactor * ZOOM_SENSITIVITY;

      if (isFinite(adjustedZoomFactor)) {
        _scale = adjustScale(_startScale + adjustedZoomFactor);
        setScale(_scale);
        setDeltaTmp(rawZoomFactor + " " + adjustedZoomFactor);
        //setInitialDistance(distance);
      }
    }
  };

  useEffect(() => {
    image = imageRef.current;
    container = containerRef.current;

    if (!isMobileDevice()) {
      container.addEventListener("wheel", handleWheel, { passive: false });
      container.addEventListener("mousedown", startPan);
      container.addEventListener("mousemove", doPan);
      container.addEventListener("mouseup", endPan);
    }

    if (isMobileDevice()) {
      container.addEventListener(
        "touchstart",
        (e) => {
          e.stopPropagation();
          e.preventDefault();
          if (e.touches.length === 1) {
            startPan(e);
          } else if (e.touches.length === 2) {
            const distance = getDistanceBetweenTouches(e);
            if (distance > 0) {
              // Ensure the initialDistance is not zero
              setInitialDistance(distance);
              _initialDistance = distance;
              setStartScale(_scale);
              _startScale = _scale;
              setIsZooming(true);
            }
          }
        },
        { passive: false }
      );

      container.addEventListener(
        "touchmove",
        (e) => {
          e.stopPropagation();
          e.preventDefault();
          if (e.touches.length === 1) {
            doPan(e);
          } else if (e.touches.length === 2) {
            handlePinchZoom(e);
          }
        },
        { passive: false }
      );

      container.addEventListener("touchend", endPan());
      container.addEventListener("touchcancel", endPan());
    }

    image.addEventListener("dragstart", function (e) {
      e.stopPropagation();
      e.preventDefault();
    });

    return () => {
      if (!isMobileDevice()) {
        container.removeEventListener("wheel", handleWheel);
        container.removeEventListener("mousedown", startPan);
        container.removeEventListener("mousemove", doPan);
        container.removeEventListener("mouseup", endPan);
      } else {
        container.removeEventListener("touchstart", startPan);
        container.removeEventListener("touchmove", doPan);
        container.removeEventListener("touchend", endPan);
        container.removeEventListener("touchcancel", endPan);
      }
    };
  }, []);

  const adjustScale = (scaleTmp) => {
    return Math.min(Math.max(0.125, scaleTmp), 7);
  };

  useEffect(() => {
    console.log("resize / move img", imgX, imgY, scale, data.commentLoc);

    image = imageRef.current;
    container = containerRef.current;
    comment = commentRef.current;

    var newScale = adjustScale(scale);

    image.style.transform = `translate(${imgX}px, ${imgY}px) scale(${newScale})`;

    var xPerc = data.commentLoc.x / size.width;
    var yPerc = data.commentLoc.y / size.height;
    const imgInfo = image.getBoundingClientRect();
    const containerInfo = containerRef.current.getBoundingClientRect();
    setCommentLoc({
      x: imgInfo.left - containerInfo.left + xPerc * (newScale * size.width),
      y: imgInfo.top - containerInfo.top + yPerc * (newScale * size.height),
    });
  }, [scale, imgX, imgY]);

  const adjustTextboxHeight = () => {
    if (!textareaReplyRef || !textareaReplyRef.current) {
      setCommentBoxHeight(REPLY_INPUT_INITIAL_HEIGHT);
      return;
    }

    const textarea = textareaReplyRef.current;
    setCommentBoxHeight(
      textarea.value == "" ? REPLY_INPUT_INITIAL_HEIGHT : textarea.scrollHeight
    );
    scrollPopoverToBottom();
  };

  const handleCommentInput = async (e) => {
    const textarea = e.target;
    setReplyValue(e.target.value);

    if (e.target.value == "") {
      setCommentingState("initial");
    } else if (e.target.value.length > 0 && commentingState == "initial") {
      setCommentingState("typing");
    }

    adjustTextboxHeight();

    const currentWord = getCurrentWord(textarea); //words.pop();
    const prevWord = getPrevWord(textarea); // words.pop();

    if (
      prevWord &&
      isEmail(prevWord.substring(1)) &&
      currentWord &&
      !currentWord.startsWith("@")
    ) {
      setAutoSuggestValue("");
    } else if (currentWord && currentWord.startsWith("@")) {
      setAutoSuggestValue(currentWord);
    } else if (prevWord && prevWord.startsWith("@")) {
      setAutoSuggestValue(prevWord + " " + currentWord);
    } else {
      setAutoSuggestValue("");
      setPopoverOpen(false);
    }
  };

  const handleCommentKeyDown = async (e) => {
    const textarea = e.target;

    if (popoverOpen) {
      const currentWord = getCurrentWord(textarea);
      if (currentWord.startsWith("@") || 1) {
        if (
          e.key === "ArrowUp" ||
          e.keyCode === 38 ||
          e.key === "ArrowDown" ||
          e.keyCode === 40 ||
          e.key === "Enter" ||
          e.keyCode === 13 ||
          e.key === "Escape" ||
          e.keyCode === 27
        ) {
          e.preventDefault();
          autoSuggestRef.current.dispatchEvent(new KeyboardEvent("keydown", e));
          return;
        }
      }
    }

    if ((e.which == 13 || e.keyCode == 13) && e.metaKey) {
      e.target.value += "\r\n";
      handleCommentInput(e);
      return;
    }

    if (
      (e.which == 13 || e.keyCode == 13) &&
      !e.shiftKey &&
      e.target.value != "" &&
      !e.ctrlKey &&
      !e.altKey &&
      !e.metaKey
    ) {
      //alert("submit");
      e.stopPropagation();
      e.preventDefault();
      // onReplySubmit(textarea.value);
      // textarea.value = "";
      // setReplyValue("");
      submitReply();
      return;
    }
  };

  const submitReply = () => {
    const textarea = textareaReplyRef.current;
    onReplySubmit(textarea.value);
    textarea.value = "";
    setReplyValue("");
  };

  const getGoogleAuthToken = () => {
    const getGoogleAccessToken = httpsCallable(functions, "getAccessToken");
    return getGoogleAccessToken({})
      .then((result) => {
        if (result && result.data && result.data.access_token) {
          return result.data.access_token;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchContacts = () => {
    if (!authUser.uid) {
      return;
    }

    getGoogleAuthToken().then((result) => {
      if (result != undefined && result != "") {
        var token = result;
        fetch(
          "https://people.googleapis.com/v1/otherContacts?pageSize=1000&readMask=emailAddresses%2Cnames%2Cmetadata%2Cphotos&sources=READ_SOURCE_TYPE_CONTACT&key=AIzaSyBDHaVkRHK-hMRkUr2AiipaqiO3OYZTGkY&access_token=" +
            token
        )
          .then((res) => res.json())
          .then((json) => {
            if(json.error && json.error.message.includes("insufficient")) {
              console.log("error can't get contacts");
              setNoContactsPermissions(true);
              return;
            }

            var contacts = [];
            var contactsByEmail = [];
            var fetchedContacts = json.otherContacts ? json.otherContacts : [];

            fetchedContacts.forEach((element) => {
              var name = "";
              var id = "";
              var photoUrl = "";
              var lastUpdated = 0;
              if (element.emailAddresses && element.emailAddresses.length > 0) {
                name = element.emailAddresses[0].value;
                id = element.emailAddresses[0].value;
              }

              if (element.names && element.names.length > 0) {
                name = element.names[0].displayName;
              }

              if (element.photos && element.photos.length > 0) {
                photoUrl = element.photos[0].url;
              }

              if (
                element.metadata &&
                element.metadata.sources &&
                element.metadata.sources.length > 0
              ) {
                lastUpdated = Math.round(
                  new Date(element.metadata.sources[0].updateTime).getTime() /
                    1000
                );
              }

              if (!contactsByEmail[id]) {
                var contact = {
                  label: name,
                  value: id,
                  photoUrl: photoUrl,
                  lastUpdated: lastUpdated,
                };

                contactsByEmail[id] = contact;
                contacts.push(contact);
              }
            });

            contacts.sort((a, b) => {
              return b.lastUpdated - a.lastUpdated;
            });

            setContacts(contacts);
            setFoundContacts(contacts.slice(0, 10));
            setContactsByEmail(contactsByEmail);
          })
          .catch((error) => {
            console.log(error);
            setNoContactsPermissions(true);
          });
      } else {
        setContacts([]);
        setFoundContacts([]);
        setContactsByEmail([]);
      }
    });
  };

  const searchContacts = (query) => {
    if (!authUser.uid) {
      return;
    }

    getGoogleAuthToken().then((result) => {
      if (result != undefined && result != "") {
        var token = result;
        fetch(
          "https://people.googleapis.com/v1/otherContacts:search?pageSize=10&query=" +
            encodeURIComponent(query) +
            "&readMask=emailAddresses%2Cnames%2Cmetadata%2Cphotos&key=AIzaSyBDHaVkRHK-hMRkUr2AiipaqiO3OYZTGkY&access_token=" +
            token
        )
          .then((res) => {
            console.log(res);
            return res.json();
          })
          .then((json) => {
            if(json.error && json.error.message.includes("insufficient")) {
              console.log("error can't get contacts");
              setPopoverOpen(true);
              setNoContactsPermissions(true);
              return;
            }

            console.log(json);
            var contactResults = json.results ? json.results : [];

            if (autoSuggestValue == "") return;
            if (!contactResults) return;

            var contacts = [];
            var contactsByEmail = [];

            contactResults.forEach((element) => {
              var p = element.person;
              var name = "";
              var id = "";
              var photoUrl = "";
              if (p.emailAddresses && p.emailAddresses.length > 0) {
                name = p.emailAddresses[0].value;
                id = p.emailAddresses[0].value;
              }

              if (p.names && p.names.length > 0) {
                name = p.names[0].displayName;
              }

              if (p.photos && p.photos.length > 0) {
                photoUrl = p.photos[0].url;
              }

              var contact = { label: name, value: id, photoUrl: photoUrl };
              contactsByEmail[id] = contact;
              contacts.push(contact);
            });

            setFoundContacts(contacts);
            if (contacts.length == 0) {
              setPopoverOpen(false);
            } else {
              setPopoverOpen(true);
            }
          })
          .catch((error) => {
            console.log(error);
            setNoContactsPermissions(true);
          });
      } else {
        setFoundContacts([]);
      }
    });
  };

  const signInWithGoogle = () => {
    var emailTmp = targetEmail;
    if(authUser.uid) {
      emailTmp = authUser.email;
    }
    window.location =
      "/login?" +
      (emailTmp != null ? "email=" + emailTmp + "&" : "") +
      "referrer=" +
      window.location;
  };

  const onCommandSelect = useCallback((value) => {
    if (value == "googlesignin") {
      signInWithGoogle();
      return;
    }

    const textarea = textareaReplyRef.current;

    if (textarea) {
      var replaceStr = "@" + value + " ";
      var commentStr = textarea.value;

      const currentWord = getCurrentWord(textarea);
      const prevWord = getPrevWord(textarea);

      if (currentWord.startsWith("@")) {
        commentStr = replaceLast(commentStr, currentWord, replaceStr);
      } else if (
        prevWord &&
        prevWord.startsWith("@") &&
        !isEmail(prevWord.substring(1))
      ) {
        commentStr = replaceLast(
          commentStr,
          prevWord + " " + currentWord,
          replaceStr
        );
      } else {
        commentStr =
          commentStr + (commentStr.length > 0 ? " " : "") + replaceStr;
      }

      textarea.focus();
      setReplyValue(commentStr);
      setAutoSuggestValue("");
    }
  }, []);

  const clearAutoSuggest = () => {
    return autoSuggestValue.startsWith("@")
      ? autoSuggestValue.substring(1)
      : autoSuggestValue;
  };

  useEffect(() => {
    adjustTextboxHeight();
  }, [replyValue]);

  useEffect(() => {
    if (autoSuggestValue == "@" && (!authUser.uid || noContactsPermissions)) {
      setPopoverOpen(true);
      return;
    } else if (autoSuggestValue == "") {
      setPopoverOpen(false);
      return;
    } else if (autoSuggestValue == "@" && contacts.length > 0) {
      setPopoverOpen(true);
      setFoundContacts(contacts.slice(0, 10));
      return;
    } else {
      searchContacts(clearAutoSuggest());
    }
  }, [autoSuggestValue]);

  const handlePopoverOpen = (e) => {
    e.preventDefault();
  };

  const handleTagPeopleClick = (e) => {
    e.preventDefault();

    if (popoverOpen && autoSuggestValue == "@") {
      setPopoverOpen(false);
      setAutoSuggestValue("");
    } else if (!popoverOpen && autoSuggestValue == "") {
      setAutoSuggestValue("@");
    }
  };

  return (
    <>
      <div ref={containerRef} style={styles.container} className="flex flex-1">
        <div
          ref={imageRef}
          style={styles.content}
          className={
            (showImages ? "opacity-100" : "opacity-0") +
            " transition-opacity duration-200"
          }
        >
          <AnimatePresence mode="wait" initial={true}>
            <motion.img
              src={bgSrc}
              alt={alt}
              style={{
                ...styles.imageBg,
                width: size.width,
                height: size.height,
              }}
              className="rounded-xl"
              onLoad={() => {
                setFirstTimeImgLoad(true);
              }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0, transition: { duration: 0.5 } }}
              animate={{ opacity: 0.5 }}
              transition={{ duration: 0.25 }}
              key={bgSrc}
            />

            <motion.img
              src={src}
              alt={alt}
              style={{
                ...styles.image,
                width: crop.width,
                height: crop.height,
                left: crop.x,
                top: crop.y,
              }}
              className={"rounded-xl" + " " + (viewable ? "" : "hidden")}
              initial={{ opacity: 0 }}
              animate={{ opacity: viewable ? 1 : 0 }}
              exit={{ opacity: 0, transition: { duration: 0.2, delay: 0 } }}
              transition={{ duration: 0.5, delay: 0.4 }}
              key={src}
              onLoad={() => {
                setTimeout(() => {
                  console.log("loaded image2");
                  setShowComment(true);
                }, 800);
                setTimeout(() => {
                  console.log("loaded image2");
                  setShowCommentBubble(true);
                }, 300);
              }}
            />
          </AnimatePresence>
        </div>
        <AnimatePresence mode="wait" initial={false}>
          {
            <div
              ref={commentRef}
              style={{
                ...styles.comment,
                left: commentLoc.x,
                top: commentLoc.y,
              }}
            >
              <Popover open={!isPanning && showComment}>
                <PopoverTrigger asChild>
                  <motion.div
                    className="commentBubble"
                    animate={{
                      opacity: showCommentBubble ? 1 : 0,
                      scale: showCommentBubble ? 1 : 0.7,
                    }}
                    transition={{ type: "spring", bounce: 0.5 }}
                  >
                    <img
                      src={
                        viewable
                          ? user?.photoURL || constants.DEFAULT_USER_IMG
                          : constants.DEFAULT_USER_IMG
                      }
                      className="commentBubbleAvatar"
                      referrerPolicy="no-referrer"
                    />
                  </motion.div>
                </PopoverTrigger>
                {(true || viewable) && (
                  <PopoverContent
                    className={
                      "w-80 max-h-[700px] overflow-y-auto pb-0 " +
                      (isMobile ? " hidden" : "")
                    }
                    side="right"
                    sideOffset={12}
                    align="start"
                  >
                    <div
                      className="grid"
                      key={"comment.content." + data.createdDateTime.seconds}
                    >
                      <div
                        className="space-y-1 pb-4"
                        key={"comment." + data.createdDateTime.seconds}
                      >
                        <div className="flex flex-row align-middle items-center justify-between">
                          <div className="flex flex-row flex-1 space-x-2 align-middle items-center">
                            {viewable && (
                              <img
                                src={
                                  viewable
                                    ? user?.photoURL ||
                                      constants.DEFAULT_USER_IMG
                                    : constants.DEFAULT_USER_IMG
                                }
                                className="w-6 h-6 inline-block rounded-xl"
                                referrerPolicy="no-referrer"
                              />
                            )}
                            {!viewable && (
                              <Skeleton className="h-6 w-6 rounded-xl" />
                            )}
                            {viewable && (
                              <p
                                className={
                                  "font-medium leading-none text-sm flex-1"
                                }
                              >
                                {user?.name || constants.DEFAULT_USER_NAME}
                              </p>
                            )}

                            {!viewable && (
                              <Skeleton className="h-3 w-[100px]" />
                            )}
                          </div>
                          {viewable && (
                            <div className="text-xs flex flex-row space-x-1 text-muted-foreground">
                              <TooltipProvider delayDuration={250}>
                                <Tooltip>
                                  <TooltipTrigger>
                                    <TimeAgo
                                      date={data.createdDateTime.seconds * 1000}
                                      formatter={timeAgoFormatter}
                                    />
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <div className="flex flex-col space-y-1 text-xs py-1">
                                      <Moment local unix format="LLLL">
                                        {data.createdDateTime.seconds}
                                      </Moment>
                                    </div>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                              {data.privacy != "public" && (
                                <TooltipProvider delayDuration={250}>
                                  <Tooltip>
                                    <TooltipTrigger>
                                      {data.privacy == "private" && (
                                        <Icon type="lock" />
                                      )}
                                      {data.privacy == "super private" && (
                                        <Icon type="incognito" />
                                      )}
                                    </TooltipTrigger>
                                    <TooltipContent>
                                      <div className="flex flex-col space-y-1 text-xs py-1">
                                        <p className="font-semibold capitalize">
                                          {data.privacy}
                                        </p>
                                        <p>
                                          {data.privacy == "private" &&
                                            "Only shared with you, anyone @mentioned, and anyone with a @" +
                                              data.site +
                                              " email"}
                                          {data.privacy == "super private" &&
                                            "Only shared with you and anyone @mentioned"}
                                        </p>
                                      </div>
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              )}
                            </div>
                          )}
                          {!viewable && (
                            <div className="flex flex-row space-x-1 items-center align-middle text-slate-500 rounded-xl bg-accent py-1 px-2">
                              <Icon type="lock" />
                              <p className="text-xs">Private</p>
                            </div>
                          )}
                        </div>
                        <div className="flex">
                          {viewable && (
                            <p
                              className={
                                "leading-normal text-sm ml-8" +
                                " " +
                                (!viewable ? "blur-sm opacity-70" : "")
                              }
                            >
                              {data.comment}
                            </p>
                          )}
                          {!viewable && (
                            <div className="mt-3 space-y-2 ml-8">
                              <Skeleton className="h-3 w-[260px]" />
                              <Skeleton className="h-3 w-[260px]" />
                              <Skeleton className="h-3 w-[180px]" />
                            </div>
                          )}
                        </div>
                      </div>

                      {viewable &&
                        replies &&
                        replies
                          .sort(
                            (a, b) =>
                              a.createdDateTime.seconds -
                              b.createdDateTime.seconds
                          )
                          .map((reply) => (
                            <div
                              className="space-y-1 pb-4"
                              key={"reply." + reply.createdDateTime.seconds}
                            >
                              <div className="flex space-x-2 justify-start align-middle items-center">
                                <img
                                  src={
                                    reply.userData?.photoURL ||
                                    constants.DEFAULT_USER_IMG
                                  }
                                  className="w-6 h-6 inline-block rounded-xl"
                                  referrerPolicy="no-referrer"
                                />
                                <p className="font-medium leading-none text-sm flex-1">
                                  {reply.userData?.name ||
                                    constants.DEFAULT_USER_NAME}
                                </p>
                                <p className="text-xs text-muted-foreground">
                                  <TimeAgo
                                    date={reply.createdDateTime.seconds * 1000}
                                    formatter={timeAgoFormatter}
                                  />
                                </p>
                              </div>
                              <div className="flex">
                                <div
                                  className="leading-normal flex-1 text-sm ml-8"
                                  dangerouslySetInnerHTML={{
                                    __html: reply.comment?.replaceAll(
                                      "\n",
                                      "<br />"
                                    ),
                                  }}
                                ></div>
                              </div>
                            </div>
                          ))}
                      <div className="" ref={repliesPopoverRef} />
                      {viewable && (
                        <div className="flex space-x-2 justify-start py-4 pb-3 sticky bottom-0  w-auto bg-white border-t border-slate-200">
                          <img
                            src={
                              authUser?.photoURL || constants.DEFAULT_USER_IMG
                            }
                            className="w-6 h-6 inline-block rounded-xl"
                            referrerPolicy="no-referrer"
                          />
                          <Popover
                            open={popoverOpen && commentingState != "initial"}
                          >
                            <PopoverAnchor asChild>
                              <div className={"flex-1 mt-0.5"}>
                                <textarea
                                  id="commentTextarea"
                                  ref={textareaReplyRef}
                                  value={replyValue}
                                  className={
                                    "inputTextBox text-sm flex-1 w-full bg-transparent "
                                  }
                                  style={{ height: commentBoxHeight }}
                                  autoFocus
                                  focus
                                  placeholder="Reply..."
                                  onInput={handleCommentInput}
                                  onKeyDownCapture={handleCommentKeyDown}
                                  autoComplete="off"
                                  autoCorrect="off"
                                />
                                {replyValue && replyValue.length > 0 && (
                                  <div
                                    className={
                                      "flex flex-row pb-1 justify-between pt-1"
                                    }
                                  >
                                    <Button
                                      onClick={handleTagPeopleClick}
                                      variant={"ghost"}
                                      size={"sm"}
                                      className="-ml-3"
                                    >
                                      <Icon type="mention" /> Tag People
                                    </Button>
                                    <SendButton
                                      ref={replyButtonRef}
                                      onClick={() => submitReply()}
                                    />
                                  </div>
                                )}
                              </div>
                            </PopoverAnchor>
                            <PopoverContent
                              className={"p-0 font-sans"}
                              onOpenAutoFocus={handlePopoverOpen}
                              onCloseAutoFocus={handlePopoverOpen}
                              align="start"
                            >
                              <div className="w-popover">
                                <Command shouldFilter={false}>
                                  <div className="hidden">
                                    <CommandInput ref={autoSuggestRef} />
                                  </div>
                                  <CommandGroup>
                                    {(!authUser.uid || noContactsPermissions) && (
                                      <CommandItem
                                        key="signIn"
                                        value="googleSignIn"
                                        onSelect={onCommandSelect}
                                      >
                                        <div className="flex justify-between space-x-2 items-center py-1 px-2">
                                          <Avatar className="h-7 w-7">
                                            <AvatarImage
                                              src="https://user-images.githubusercontent.com/1770056/58111071-c2941c80-7bbe-11e9-8cac-1c3202dffb26.png"
                                              alt="google sign in"
                                            />
                                          </Avatar>
                                          <div className="space-y-0">
                                            <h4 className="text-sm text-gray-800 break-all">
                                              <b>{noContactsPermissions ? "Grant access to your contacts" : "Sign in to @mention"}</b>
                                            </h4>
                                            <p className="text-xs text-gray-500 break-all">
                                              {noContactsPermissions ? "Access needed to @mention people" : "Tag your Google contacts"}
                                            </p>
                                          </div>
                                        </div>
                                      </CommandItem>
                                    )}

                                    {(authUser.uid && noContactsPermissions == false) &&
                                      foundContacts.map((contact) => (
                                        <CommandItem
                                          key={
                                            contact.value + "-" + contact.label
                                          }
                                          value={contact.value}
                                          onSelect={onCommandSelect}
                                        >
                                          <div className="flex justify-between space-x-2 items-center p-1">
                                            {contact?.photoUrl && (
                                              <Avatar className="w-8 h-8">
                                                <AvatarImage
                                                  src={contact.photoUrl}
                                                  alt={contact.label}
                                                />
                                                <AvatarFallback>
                                                  CN
                                                </AvatarFallback>
                                              </Avatar>
                                            )}
                                            <div className="">
                                              <h4 className="text-xs text-gray-800 break-all">
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      contact.label.replace(
                                                        new RegExp(
                                                          clearAutoSuggest(),
                                                          "gi"
                                                        ),
                                                        (str) => `<b>${str}</b>`
                                                      ),
                                                  }}
                                                />
                                              </h4>
                                              <div className="text-xs text-gray-500 break-all">
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      contact.value.replace(
                                                        new RegExp(
                                                          clearAutoSuggest(),
                                                          "gi"
                                                        ),
                                                        (str) => `<b>${str}</b>`
                                                      ),
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </CommandItem>
                                      ))}
                                  </CommandGroup>
                                </Command>
                              </div>
                            </PopoverContent>
                          </Popover>
                        </div>
                      )}
                      {!viewable && (
                        <div className="border-t border-slate-200 w-full py-2">
                          <Button
                            onClick={() => signInWithGoogle()}
                            variant="ghost"
                            className="w-full space-x-2"
                            tabindex="-1"
                          >
                            <Avatar>
                              <AvatarImage
                                src="https://user-images.githubusercontent.com/1770056/58111071-c2941c80-7bbe-11e9-8cac-1c3202dffb26.png"
                                alt="google sign in"
                              />
                            </Avatar>
                            <p>Sign in to view</p>
                          </Button>
                        </div>
                      )}
                    </div>
                  </PopoverContent>
                )}
              </Popover>
            </div>
          }
        </AnimatePresence>
        {viewable && !isMobile && (
          <div className="absolute bottom-4 w-full">
            <div className="flex flex-row justify-center text-xs text-secondary-foreground/60 space-x-2">
              <span className="px-4 py-2 rounded-full bg-secondary/50 backdrop-blur-lg truncate max-w-lg">
                <a
                  href={data.pageUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="underline underline-offset-4 hover:text-secondary-foreground"
                >
                  {data.pageUrl}
                </a>
              </span>
              <span className="px-4 py-2 rounded-full bg-secondary/50 backdrop-blur-lg">
                {data.windowSize.width} x {data.windowSize.height}
              </span>
              <span className="px-4 py-2 rounded-full bg-secondary/50 backdrop-blur-lg">
                {data.ua.browser.name} {data.ua.browser.version}
              </span>
              <span className="px-4 py-2 rounded-full bg-secondary/50 backdrop-blur-lg">
                {data.ua.os.name} {data.ua.os.version}
              </span>
            </div>
          </div>
        )}
      </div>
      {isMobile && (
        <div className="h-1/2 w-full bg-white p-4 overflow-y-auto">
          {!viewable && (
            <div className="">
              <Button
                onClick={() => signInWithGoogle()}
                variant="secondary"
                className="w-full space-x-2"
                tabindex="-1"
              >
                <Avatar>
                  <AvatarImage
                    src="https://user-images.githubusercontent.com/1770056/58111071-c2941c80-7bbe-11e9-8cac-1c3202dffb26.png"
                    alt="google sign in"
                  />
                </Avatar>
                <p>Sign in to view</p>
              </Button>
            </div>
          )}

          <div
            className={"space-y-1 pb-4 " + (viewable ? "" : "hidden")}
            key={"comment." + data.createdDateTime.seconds}
          >
            <div className="flex space-x-2 justify-start align-middle items-center">
              <img
                src={user?.photoURL || constants.DEFAULT_USER_IMG}
                className="w-6 h-6 inline-block rounded-xl"
                referrerPolicy="no-referrer"
              />
              <p className="font-medium leading-none text-sm flex-1">
                {user?.name || constants.DEFAULT_USER_NAME}
              </p>
              <p className="text-xs text-muted-foreground">
                <TimeAgo
                  datetime={data.createdDateTime.seconds * 1000}
                  opts={{ minInterval: 60 }}
                />
              </p>
            </div>
            <div className="flex space-x-6">
              <p className="leading-normal text-sm ml-8">{data.comment}</p>
            </div>
          </div>

          {replies &&
            replies.map((reply) => (
              <div
                className="space-y-1 pb-4"
                key={"reply." + reply.createdDateTime.seconds}
              >
                <div className="flex space-x-2 justify-start align-middle items-center">
                  <img
                    src={reply.userData?.photoURL || constants.DEFAULT_USER_IMG}
                    className="w-6 h-6 inline-block rounded-xl"
                    referrerPolicy="no-referrer"
                  />
                  <p className="font-medium leading-none text-sm flex-1">
                    {reply.userData?.name || constants.DEFAULT_USER_NAME}
                  </p>
                  <p className="text-xs text-muted-foreground">
                    <TimeAgo
                      datetime={reply.createdDateTime.seconds * 1000}
                      opts={{ minInterval: 60 }}
                    />
                  </p>
                </div>
                <div className="flex">
                  <div
                    className="leading-normal flex-1 text-sm ml-8"
                    dangerouslySetInnerHTML={{
                      __html: reply.comment?.replaceAll("\n", "<br />"),
                    }}
                  ></div>
                </div>
              </div>
            ))}

          <div className="w-full py-4 text-sm border-t border-t-gray-200 text-gray-700 b-0 items-center flex justify-center flex-col space-y-1 mt-4">
            <DesktopIcon />
            <div>View on desktop to reply back</div>
          </div>

          <div className="h-1/4 block w-full bg-white" />
        </div>
      )}
    </>
  );
};

export default ImageViewer;
