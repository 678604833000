/* global chrome */
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  signInWithRedirect,
  GoogleAuthProvider,
  getRedirectResult,
  onAuthStateChanged,
  signInWithPopup,
  signInWithCredential,
  AuthCredential,
} from "firebase/auth";
import { db, storage, auth, functions } from "./shared/firebase";
import * as constants from "./shared/constants";
import { motion, AnimatePresence } from "framer-motion";
import Spinner from "./components/ui/spinner";

var parser = require("ua-parser-js");

function Login() {
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
  provider.addScope("https://www.googleapis.com/auth/contacts.other.readonly");
  provider.addScope("https://www.googleapis.com/auth/directory.readonly");
  provider.addScope("https://www.googleapis.com/auth/userinfo.profile");

  const [urlParams] = useSearchParams();
  const [signedIn, setSignedIn] = useState(false);

  const currentUA = parser(window.navigator.userAgent);
  const isMobile = currentUA.device.type == "mobile";

  console.log(urlParams.get("referrer"));

  provider.setCustomParameters({
    access_type: "offline",
    prompt: "consent",
    login_hint: urlParams.get("email") ? urlParams.get("email") : "",
  });

  async function getGoogleAuthToken(oauthResult) {
    var token = "";
    var credential = null;
    var idToken = null;
    if (oauthResult) {
      credential = GoogleAuthProvider.credentialFromResult(oauthResult);
      token = credential.accessToken;
    } else {
      idToken = await auth.currentUser.getIdToken();
      credential = GoogleAuthProvider.credential(idToken);
      token = auth.currentUser.accessToken;
    }
    console.log("login data", {
      auth: auth,
      signInResult: oauthResult,
      googleToken: token,
      credential: credential,
      idToken: idToken,
    });

    setSignedIn(true);
    //alert("token " + token);
    //alert("refresh token " + credential.toJSON());
    //alert("oauthResult " + oauthResult.toJSON());

    if (urlParams.get("referrer")) {
      window.location = urlParams.get("referrer");
    } else if (isMobile) {
      window.location = "https://crit.ly";
    } else if (chrome.runtime) {
      var extId = urlParams.get("isDev")
        ? constants.extensionIdDev
        : constants.extensionId;
      console.log("close popup", extId);
      chrome.runtime.sendMessage(extId, {
        msg: "popupClose",
        auth: auth,
        signInResult: oauthResult,
        googleToken: token,
        credential: credential,
        idToken: idToken,
      });
    }
  }

  useEffect(() => {
    // try {
    //   var result = await getRedirectResult(auth);
    //   if (!result) {
    //     console.log("not signed in", auth, result);
    //     // setTimeout(() => {
    //     //   signInWithRedirect(auth, provider);
    //     // }, 5000);
    //   } else {
    //     console.log("signed in", auth, result);
    //     getGoogleAuthToken(result);
    //   }
    // } catch (error) {
    //   console.log("redirect error", error);

    //   if (!auth.currentUser) {
    //     console.log("not signed in", auth);
    //     // setTimeout(() => {
    //     //   signInWithRedirect(auth, provider);
    //     // }, 100);
    //   }
    // }

    const loginFlowDesktop = async () => {
      try {
        const result = await getRedirectResult(auth);

        //const result = await getRedirectResult(auth);
        console.log("redirect results", auth, result);
        if (result) {
          console.log("got result", auth, result);
          getGoogleAuthToken(result);
        } else {
          console.log("didn't get a result", auth, result);
          setTimeout(() => {
            signInWithRedirect(auth, provider);
          }, 100);
        }
      } catch (error) {
        console.log("redirect error", error);
      }
    };

    const loginFlowMobile = async () => {
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log("got result", auth, result);
          getGoogleAuthToken(result);
        })
        .catch((error) => {
          console.log("redirect error", error);
        });
    };

    setTimeout(() => {
      loginFlowDesktop();
    }, 500);

    onAuthStateChanged(auth, (user) => {
      console.log("auth state change", user);
      if (user && user.uid) {
        console.log("signed in");
        //auth.signOut();
        //debugRedirectResult();
      }
    });

    // getRedirectResult(auth)
    //   .then((result) => {
    //     console.log("redirect results", result, auth);

    //     if (!result) {
    //       console.log("not signed in", auth, result);
    //       setTimeout(() => {
    //         signInWithRedirect(auth, provider);
    //       }, 5000);
    //     } else {
    //       console.log("signed in", auth, result);
    //       getGoogleAuthToken(result);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("redirect error", error);

    //     if (!auth.currentUser) {
    //       console.log("not signed in", auth);
    //       // setTimeout(() => {
    //       //   signInWithRedirect(auth, provider);
    //       // }, 100);
    //     }
    //   });
  }, []);

  return (
    <div className="h-screen flex items-center justify-center">
      <Spinner />
    </div>
  );
}

export default Login;
