import * as React from "react";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import Icon from "./icons";
import { cn } from "../../lib/utils";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
    searchPlaceholder?: string;
    options?: [{value: string, label: string, img: string, iconType?: string}];
    value?: string;
    img?: string; 
    iconType?: string; 
    onChangeValue?: (value: string) => {};
  }

const Combobox = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, value = "", searchPlaceholder = "Search", options = [], onChangeValue = () => {}, img, iconType, ...props }, ref) => {
    const [open, setOpen] = React.useState(false);

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
            variant={"secondary"}
          >
            {img && <img src={img} className="w-4 h-4 mr-2" />}
            {iconType && <Icon type={iconType} className="w-4 h-4 mr-2" />}
            {value
              ? options.find((option) => option.value === value)?.label
              : searchPlaceholder}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0 rounded-md">
          <Command className="bg-transparent">
            <CommandInput placeholder={searchPlaceholder} className="h-9" />
            <CommandEmpty>No results</CommandEmpty>
            <CommandGroup>
              {options.length > 0 &&
                options.map((option) => (
                  <CommandItem
                    key={option?.value}
                    onSelect={(currentValue) => {
                      //setValue(currentValue === value ? "" : currentValue);
                      onChangeValue(currentValue);
                      setOpen(false);
                    }}
                    className="py-2 px-2 rounded-sm"
                  >
                    {option?.img && <img src={option?.img} className="w-4 h-4 mr-2" />}
                    {option?.iconType && <Icon type={option?.iconType} className="w-4 h-4 mr-2" />}
                    {option?.label}
                    <CheckIcon
                      className={cn(
                        "ml-auto h-4 w-4",
                        value === option.value ? "opacity-100" : "opacity-0"
                      )}
                    />
                  </CommandItem>
                ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);
Combobox.displayName = "Input";

export {Combobox};
